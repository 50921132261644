import React, { useState } from "react";
import Stars from "./Stars";




function CustomReviews() {

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
    const moverReview = [
        {
          UserName: "Kary A",
          Date: "3/5/2022",
          Rate: 5,
          Description: "Great, honest, hard-working people.",
          red: "homeadvisor",
          enlace: "https://www.homeadvisor.com/rated.JrRoofCleaningLLC.110982623.html",
          ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/redes%20y%20directorios%2Fhome-advisor-01.png?alt=media&token=a7cd56a8-5d77-46a3-83d3-2c26b120e814"
        },
        {
          UserName: "Carol W.",
          Date: "2/26/2022",
          Rate: 4.55,
          Description: "Good estimate On time Above and beyond trimming bushes over gutter Very quick but safe Photos of before and after of some small repairs needed Great attitude",
          red: "homeadvisor",
          enlace: "https://www.homeadvisor.com/rated.JrRoofCleaningLLC.110982623.html",
          ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/redes%20y%20directorios%2Fhome-advisor-01.png?alt=media&token=a7cd56a8-5d77-46a3-83d3-2c26b120e814"
        },
      ]
      
     


    return (
        <section className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1">
            {
                moverReview.map((item, index) => {
                    return (
                        <article key={index} className="h-auto p-2 flex flex-col rounded-3xl bg-gray-100 w-auto">
                            <div className="flex flex-col">
                                <div className="p-2 flex space-x-2">
                                    <div className="flex justify-center">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                            alt="perfil-foto"
                                            className="w-[50px] h-auto rounded-full object-cover" />
                                    </div>
                                    <div>
                                        <span className="text-[20px] font-semibold"> {item?.UserName} </span>
                                        <Stars score={item?.Rate} />
                                    </div>
                                    <div className="flex items-end text-[13px] text-gray-400">
                                        <span>{item?.Date}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 ">
                                <ReadMore >{item?.Description}</ReadMore>
                            </div>
                            <div className="w-full flex items-center space-x-3 ml-3">
                                <div className="w-[55px] h-[55px] flex items-center">
                                    <img src={item?.ima} alt="agni" className="w-full mx-auto" />
                                </div>
                                <div className="flex flex-col items-center justify-start">
                                    <p>Posted On</p>
                                    <a className="text-blue-500 w-full" href={item?.enlace}>{item?.red}</a>
                                </div>
                            </div>
                        </article>
                    );
                })
            }
        </section>
    );
}

export default CustomReviews;